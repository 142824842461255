import { Button, Card, Form, Input, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { UsersTableFilter } from '@/types.ts';
import { useRoles } from '@/features/users';

type UsersFilterProps = {
    handleSearch: (values: UsersTableFilter) => void;
};

export const UsersFilter: React.FC<UsersFilterProps> = ({ handleSearch }) => {
    const { t } = useTranslation(['users', 'common']);
    const { data: roles } = useRoles();

    const onSearch = (values: UsersTableFilter) => {
        handleSearch(values);
    };

    return (
        <Card className={'mb-4'}>
            <Form
                onFinish={onSearch}
                className="grid grid-flow-row md:grid-flow-col gap-4"
                labelCol={{ xs: { flex: '1 1 33.33%' }, md: { flex: '1 0 auto' } }}
                wrapperCol={{ xs: { flex: '1 1 66.66%' }, md: { flex: '1 0 auto' } }}
            >
                <Form.Item<UsersTableFilter> name="counterparty" label={t('users:counterparty')} className={'mb-0'}>
                    <Input placeholder={t('users:counterparty')} allowClear />
                </Form.Item>
                <Form.Item<UsersTableFilter> name="email" label={t('users:email')} className={'mb-0'}>
                    <Input placeholder={t('users:email')} allowClear />
                </Form.Item>
                <Form.Item<UsersTableFilter> name="phone" label={t('users:phone')} className={'mb-0'}>
                    <Input placeholder={t('users:phone')} allowClear />
                </Form.Item>
                <Form.Item<UsersTableFilter> name="role_slug" label={t('users:role')} className={'mb-0'}>
                    <Select placeholder={t('users:role')} allowClear>
                        {roles?.map((item) => (
                            <Select.Option key={item} value={item}>
                                {t(item, { ns: 'common' })}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Button htmlType="submit" icon={<SearchOutlined />} type="primary">
                    {t('common:search')}
                </Button>
            </Form>
        </Card>
    );
};
