import { OsmoDeviceAnalytics, OsmoDeviceEdgeCases, OsmoDeviceEdgeCasesMap, OsmoDeviceSettings } from '@/types.ts';

export const osmoDeviceAnalytics: (keyof OsmoDeviceAnalytics)[] = [
    'TDS',
    'FLOW_RATE',
    'TOTAL_L',
    'INLET_PRESSURE',
    'PRESSURE_AFTER_PUMP',
    'PRESSURE_AFTER_MEMBRANE',
    'UP_TO_THE_SERVICE',
    'UP_TO_THE_SERVICE_DAY',
];

export const osmoDeviceEdgeCases: (keyof OsmoDeviceEdgeCases)[] = [
    'TDS',
    'PRESSURE_AFTER_PUMP',
    'PRESSURE_AFTER_MEMBRANE',
    'UP_TO_THE_SERVICE',
    'INLET_PRESSURE',
];

export const disabledEdgeCases: (keyof OsmoDeviceEdgeCases)[] = ['PRESSURE_AFTER_PUMP', 'PRESSURE_AFTER_MEMBRANE', 'INLET_PRESSURE'];

export const osmoDeviceSettings: (keyof OsmoDeviceSettings)[] = [
    'flushing_time_operating',
    'washing_interval_operating',
    'flushing_time_standby',
    'washing_interval_standby',
    'max_inlet_pressure',
    'max_pressure_drop_membrane',
];

export const defaultEdgeCasesValues: OsmoDeviceEdgeCasesMap = {
    TDS: 50,
    PRESSURE_AFTER_PUMP: 1.5,
    PRESSURE_AFTER_MEMBRANE: 1.5,
    UP_TO_THE_SERVICE: 100,
    INLET_PRESSURE: 1,
};
