import { Tooltip, Modal, Card, Divider, Flex } from 'antd';
import type { TableProps } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SortOrder, UsersTableFilter, UsersTableItem, UsersTableSortBy } from '@/types.ts';
import { ROUTES } from '@/features/router';
import { useDeleteUser, useUsers } from '@/features/users';
import { useState } from 'react';
import { useMessageApiContext } from '@/features/message-api/context';
import { TableLocale } from 'antd/es/table/interface';
import { UsersFilter } from './UsersFilter';
import axios from 'axios';
import { useRBAC } from '@/features/auth';
import { ERRORS } from '@/utils/constants.ts';
import { TelegramIcon } from '@/features/users/component/TelegramIcon.tsx';
import { AdaptiveTable } from '@/features/adaptive/components/AdaptiveTable.tsx';

export const UsersTable = () => {
    const [page, setPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [filter, setFilter] = useState<UsersTableFilter>();
    const [sortBy, setSortBy] = useState<UsersTableSortBy>();

    const { isCurrentUser } = useRBAC();
    const { mutateAsync } = useDeleteUser();
    const { data, isLoading } = useUsers(page, perPage, filter, sortBy);
    const { t } = useTranslation(['users', 'common']);
    const navigate = useNavigate();
    const { confirm } = Modal;
    const { messageApi } = useMessageApiContext();

    const showDeleteConfirm = ({ id }: UsersTableItem) => {
        confirm({
            title: t('users:delete_confirmation'),
            icon: <ExclamationCircleFilled />,
            okText: t('common:delete_ok'),
            okType: 'danger',
            cancelText: t('common:delete_cancel'),
            onOk: async () => {
                try {
                    await mutateAsync(id);
                    messageApi.open({
                        type: 'success',
                        content: t('common:message_success'),
                    });
                } catch (e) {
                    if (axios.isAxiosError(e)) {
                        let message = t('common:message_error');
                        if (e.response?.data.status === ERRORS.CONSTRAINT_ERROR) {
                            message = t('common:message_constraint_error');
                        }

                        messageApi.open({
                            type: 'error',
                            content: message,
                        });
                    }
                }
            },
            onCancel() {},
        });
    };

    const columns: TableProps<UsersTableItem>['columns'] = [
        {
            title: t('users:counterparty'),
            dataIndex: 'counterparty',
            key: 'counterparty',
            sorter: true,
        },
        {
            title: t('users:role'),
            key: 'role',
            dataIndex: 'role',
            render: (record) => t(record, { ns: 'common' }),
        },
        {
            title: t('users:phone'),
            dataIndex: 'phone',
            key: 'phone',
            render: (text, record) => {
                return (
                    <Flex className="justify-end md:justify-start md:pr-3 w-full" align={'center'} gap={8}>
                        <TelegramIcon enabled={record.is_telegram_assigned} />
                        <span>{text}</span>
                    </Flex>
                );
            },
        },
        {
            title: t('users:email'),
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: t('users:address'),
            dataIndex: 'address',
            key: 'address',
            sorter: true,
            render: (text, record) => {
                let result = `${record.country.title}, ${record.region.title}`;
                if (text) {
                    result += `, ${text}`;
                }
                return result;
            },
        },
        {
            title: t('users:referrer'),
            dataIndex: ['referrer', 'counterparty'],
            key: 'referrer',
            sorter: true,
        },
        {
            title: t('common:actions'),
            key: 'action',
            width: '92px',
            render: (_, record) => (
                <div className={'flex items-center'}>
                    <Tooltip placement="top" title={t('common:tooltip_edit')}>
                        <EditOutlined
                            onClick={() => {
                                navigate(`${ROUTES.USER_EDIT}/${record.id}`);
                            }}
                            style={{ color: '#389E0D', fontSize: 20 }}
                        />
                    </Tooltip>

                    {!isCurrentUser(record.id) && (
                        <>
                            <Divider type="vertical" />
                            <Tooltip placement="top" title={t('common:tooltip_delete')}>
                                <DeleteOutlined onClick={() => showDeleteConfirm(record)} style={{ color: '#CF1322', fontSize: 20 }} />
                            </Tooltip>
                        </>
                    )}
                </div>
            ),
        },
    ];

    const tableLocale: TableLocale = {
        emptyText: <div className={'py-[74px]'}> {t('common:emptyText')}</div>,
    };

    const handleTableChange: TableProps<UsersTableItem>['onChange'] = (pagination, _filters, sorter) => {
        if (pagination?.current) {
            setPage(pagination.current);
        }

        if (pagination?.pageSize) {
            setPerPage(pagination.pageSize);
        }

        if (!Array.isArray(sorter)) {
            let sortOrder: SortOrder;
            if (sorter.order === 'descend') {
                sortOrder = 'desc';
            } else if (sorter.order === 'ascend') {
                sortOrder = 'asc';
            }

            setSortBy({});
            if (sorter.columnKey === 'counterparty') {
                setSortBy((prevState) => {
                    const newState = { ...prevState };
                    newState.counterparty = sortOrder;
                    return newState;
                });
            }

            if (sorter.columnKey === 'address') {
                setSortBy((prevState) => {
                    const newState = { ...prevState };
                    newState.address = sortOrder;
                    return newState;
                });
            }

            if (sorter.columnKey === 'referrer') {
                setSortBy((prevState) => {
                    const newState = { ...prevState };
                    newState.referrer = sortOrder;
                    return newState;
                });
            }
        }
    };

    const handleSearch = (values: UsersTableFilter) => {
        setFilter(values);
        setPage(1);
    };

    return (
        <>
            <UsersFilter handleSearch={handleSearch} />
            <Card bordered={false} className={'shadow-none'}>
                <AdaptiveTable
                    loading={isLoading}
                    columns={columns}
                    dataSource={data?.data.paginated}
                    rowKey="id"
                    onChange={handleTableChange}
                    pagination={{
                        current: page,
                        total: data?.data.meta?.total,
                        defaultPageSize: perPage,
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                            t('common:paginationTotal', {
                                range1: range[0],
                                range2: range[1],
                                total: total,
                            }),
                    }}
                    locale={tableLocale}
                    collapseLabel={(record) => (
                        <Flex justify="space-between" align="center">
                            <span>{t('users:counterparty')}</span>
                            <span>{record.counterparty}</span>
                        </Flex>
                    )}
                    omitColumns={['counterparty']}
                />
            </Card>
        </>
    );
};
