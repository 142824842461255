import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Flex, Form, Input, Select, Tooltip } from 'antd';
import { OsmoDeviceEdgeCases, OsmoDeviceRequest, OsmoDeviceResponseForForm, OsmoDeviceSettings, UserResponse } from '@/types.ts';
import { UserAddModal, useUsers } from '@/features/users';
import { useCountries } from '@/features/countries';
import { useRegions } from '@/features/regions';
import { useTranslation } from 'react-i18next';
import { useMessageApiContext } from '@/features/message-api/context';
import { useNavigate } from 'react-router-dom';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import { ROUTES } from '@/features/router';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import axios from 'axios';
import { setFormServerErrors } from '@/utils';
import {
    defaultEdgeCasesValues,
    disabledEdgeCases,
    osmoDeviceEdgeCases,
    osmoDeviceSettings,
} from '@/features/osmo_devices/constants.ts';
import { useProvideMaintenance } from '@/features/osmo_devices/hooks/api.ts';
import { useRBAC } from '@/features/auth';

export type OsmoDeviceFormProps = {
    data?: OsmoDeviceResponseForForm;
    mutateFn: UseMutateAsyncFunction<OsmoDeviceResponseForForm, Error, OsmoDeviceRequest, unknown>;
    isLoading?: boolean;
    isSuccess?: boolean;
};

export const OsmoDeviceForm: React.FC<OsmoDeviceFormProps> = ({ data, mutateFn, isLoading, isSuccess }) => {
    const [form] = Form.useForm<OsmoDeviceRequest>();
    const countryValue = Form.useWatch('country_id', form);
    const [openAddUserModel, setOpenAddUserModel] = useState(false);
    const { data: users, refetch: refetchUsers } = useUsers(1, 100);
    const { data: countries } = useCountries(1, 100);
    const { data: regions } = useRegions(1, 100, { country_id: countryValue });
    const { mutateAsync: maintenance } = useProvideMaintenance();
    const { t } = useTranslation(['devices', 'osmo_devices']);
    const { messageApi } = useMessageApiContext();
    const navigate = useNavigate();
    const uuidValue = Form.useWatch('uuid', form);
    const { isAdmin } = useRBAC();

    useEffect(() => {
        const uuid = data?.data.uuid ?? window.crypto.randomUUID();
        const edge_cases = Object.keys(data?.data.edge_cases ?? {}).length > 0 ? data?.data.edge_cases : defaultEdgeCasesValues;

        if (isSuccess) {
            form.setFieldsValue({
                user_id: data?.data.user.id,
                country_id: data?.data.region.country.id,
                region_id: data?.data.region.id,
                address: data?.data.address,
                flushing_time_operating: data?.data.flushing_time_operating,
                washing_interval_operating: data?.data.washing_interval_operating,
                flushing_time_standby: data?.data.flushing_time_standby,
                washing_interval_standby: data?.data.washing_interval_standby,
                max_inlet_pressure: data?.data.max_inlet_pressure,
                max_pressure_drop_membrane: data?.data.max_pressure_drop_membrane,
                serial_number: data?.data.serial_number,
            });
        }

        form.setFieldValue('uuid', uuid);
        form.setFieldValue('edge_cases', edge_cases);
    }, [data, isSuccess]);

    const onSubmit = async (values: OsmoDeviceRequest) => {
        try {
            await mutateFn(values);
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
            navigate(ROUTES.OSMO_DEVICES);
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });

            if (axios.isAxiosError(e)) {
                const errors = e.response!.data.errors;
                errors && setFormServerErrors(form, errors);
            }
        }
    };

    const provideMaintenance = async () => {
        try {
            await maintenance(data?.data.id!);
            messageApi.open({
                type: 'success',
                content: t('common:message_success'),
            });
        } catch (e) {
            messageApi.open({
                type: 'error',
                content: t('common:message_error'),
            });
        }
    };

    const handleClose = () => {
        setOpenAddUserModel(false);
    };

    const handleSubmitNewUser = (user?: UserResponse) => {
        if (user) {
            refetchUsers().then(() => {
                form.setFieldValue('user_id', user.data.id);
            });
        }
    };

    const onCountryChange = () => {
        form.setFieldValue('region_id', null);
    };

    const handleCopyUUID = () => {
        if (uuidValue != null) {
            navigator.clipboard.writeText(uuidValue).then(() => {
                messageApi.open({
                    type: 'success',
                    content: t('common:copy_success'),
                });
            });
        }
    };

    return (
        <Form form={form} layout="vertical" requiredMark={false} onFinish={onSubmit}>
            <Flex vertical gap={15}>
                <Card classNames={{ body: 'flex flex-col' }} title={t('osmo_devices:device_info')} loading={isLoading}>
                    <Col xs={{ span: 24 }} md={{ span: 12 }} className="flex flex-col gap-y-6 md:flex-row md:items-end md:gap-x-4 mb-[24px]">
                        <Form.Item
                            name="user_id"
                            label={t('counterparty')}
                            rules={[{ required: true, message: t('common:rule_required') }]}
                            className={'w-full mb-0'}
                        >
                            <Select
                                placeholder={t('counterparty')}
                                options={users?.data.paginated}
                                fieldNames={{ value: 'id', label: 'counterparty' }}
                                showSearch
                                optionFilterProp={'counterparty'}
                            ></Select>
                        </Form.Item>

                        <Button htmlType={'button'} type="primary" size={'middle'} icon={<PlusOutlined />} onClick={() => setOpenAddUserModel(true)}>
                            {t('add_user')}
                        </Button>
                    </Col>
                    <div className="grid grid-flow-row gap-x-4 gap-y-6 md:grid-cols-3">
                        <Form.Item
                            name="country_id"
                            className="mb-0"
                            label={t('country')}
                            rules={[{ required: true, message: t('common:rule_required') }]}
                        >
                            <Select
                                placeholder={t('country')}
                                options={countries?.data.paginated}
                                fieldNames={{ value: 'id', label: 'title' }}
                                showSearch
                                optionFilterProp={'title'}
                                onChange={onCountryChange}
                            ></Select>
                        </Form.Item>
                        <Form.Item
                            name="region_id"
                            className="mb-0"
                            label={t('region')}
                            rules={[{ required: true, message: t('common:rule_required') }]}
                            shouldUpdate
                        >
                            <Select
                                placeholder={t('region')}
                                options={regions?.data.paginated}
                                fieldNames={{ value: 'id', label: 'title' }}
                                showSearch
                                optionFilterProp={'title'}
                                disabled={!countryValue}
                            ></Select>
                        </Form.Item>
                        <Form.Item className="mb-0" name="address" label={t('address')}>
                            <Input placeholder={t('address')} />
                        </Form.Item>
                        {isAdmin() && (
                            <>
                                <Form.Item
                                    name="uuid"
                                    label="UUID"
                                    tooltip={{
                                        title: t('osmo_devices:uuid_tooltip'),
                                        placement: 'topLeft',
                                    }}
                                    className="mb-0"
                                >
                                    <Input
                                        placeholder={t('uuid')}
                                        disabled
                                        addonAfter={
                                            <Tooltip placement="top" title={t('osmo_devices:copy_uuid')}>
                                                <CopyOutlined onClick={handleCopyUUID} />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>
                                <Form.Item<OsmoDeviceRequest>
                                    name="serial_number"
                                    label={t('serial_number')}
                                    rules={[{ required: true, message: t('common:rule_required') }]}
                                    className="mb-0"
                                >
                                    <Input placeholder={t('serial_number')} />
                                </Form.Item>
                            </>
                        )}
                    </div>
                </Card>
                {isAdmin() && (
                    <>
                        <Card title={t('osmo_devices:osmo_device_settings')} loading={isLoading}>
                            <div className="grid grid-flow-row items-end gap-y-6 md:grid-cols-3 gap-x-4">
                                {osmoDeviceSettings.map((val: keyof OsmoDeviceSettings, idx: number) => {
                                    return (
                                        <Form.Item
                                            className="mb-0"
                                            name={val}
                                            label={t(`osmo_devices:${val}`)}
                                            rules={[{ required: true, message: t('common:rule_required') }]}
                                            key={idx}
                                        >
                                            <Input placeholder={t(`osmo_devices:${val}`)} />
                                        </Form.Item>
                                    );
                                })}
                            </div>
                        </Card>
                        <Card title={t('osmo_devices:edge_cases')} loading={isLoading}>
                            <div className="grid grid-flow-row items-end gap-y-6 md:grid-cols-3 gap-x-4">
                                {osmoDeviceEdgeCases.map((val: keyof OsmoDeviceEdgeCases) => {
                                    return (
                                        <Form.Item
                                            className="mb-0"
                                            name={['edge_cases', val]}
                                            label={t(`osmo_devices:${val}`)}
                                            rules={[{ required: true, message: t('common:rule_required') }]}
                                            key={val}
                                        >
                                            <Input disabled={disabledEdgeCases.includes(val)} placeholder={t(`osmo_devices:${val}`)} />
                                        </Form.Item>
                                    );
                                })}
                            </div>
                        </Card>
                    </>
                )}
                <Card>
                    <Flex justify="space-between" className="flex-col-reverse md:flex-row gap-y-2">
                        <div className="flex w-full gap-2">
                            <Button type="primary" className="md:w-max w-full" htmlType="submit" loading={isLoading}>
                                {t('common:submit')}
                            </Button>
                            <Button
                                onClick={() => {
                                    navigate(ROUTES.DASHBOARD);
                                }}
                                className="md:w-max w-full"
                                htmlType="button"
                            >
                                {t('common:cancel')}
                            </Button>
                        </div>
                        {data?.data.need_maintenance && isAdmin() && (
                            <Button type="primary" htmlType="button" onClick={async () => await provideMaintenance()}>
                                {t('osmo_devices:maintenance')}
                            </Button>
                        )}
                    </Flex>
                </Card>
            </Flex>

            <UserAddModal isOpen={openAddUserModel} handleClose={handleClose} handleSubmit={handleSubmitNewUser} />
        </Form>
    );
};
