import React, { useMemo } from 'react';
import { Collapse, CollapseProps, Flex, List, Pagination, Spin, Table, TablePaginationConfig } from 'antd';
import type { TableProps as RcTableProps } from 'rc-table/lib/Table';
import { ColumnsType, type FilterValue, type SorterResult, type TableCurrentDataSource, type TableLocale } from 'antd/es/table/interface';
import type { SpinProps } from 'antd/es/spin';
import { useAdaptiveContext } from '@/features/adaptive/context/Context.ts';
import type { GetComponentProps, GetRowKey, RowClassName } from 'rc-table/lib/interface';
import { ColumnType } from 'antd/lib/table';
import { useTranslation } from 'react-i18next';
import '@/styles/adaptive_table.css';

export type AdaptiveTableProps<RecordType> = {
    pagination?: false | TablePaginationConfig;
    dataSource?: RcTableProps<RecordType>['data'];
    columns?: ColumnsType<RecordType>;
    loading?: boolean | SpinProps;
    onChange?: (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<RecordType> | SorterResult<RecordType>[],
        extra: TableCurrentDataSource<RecordType>
    ) => void;
    rowKey?: string | keyof RecordType | GetRowKey<RecordType>;
    locale?: TableLocale;
    onRow?: GetComponentProps<RecordType>;
    rowClassName?: string | RowClassName<RecordType>;
    collapseLabel?: (record: RecordType) => string | React.ReactNode;
    omitColumns?: React.Key[];
    scroll?: RcTableProps<RecordType>['scroll'] & {
        scrollToFirstRowOnChange?: boolean;
    };
};

const getTableValue = <T extends object, K extends keyof T>(data: T, key: K | (keyof T)[]) => {
    if (!Array.isArray(key)) {
        return data[key] ?? null;
    } else {
        let obj: any = data;
        for (const k of key) {
            obj = obj[k] ?? null;
            if (!obj) return obj;
        }

        return obj;
    }
};

const createList = <T extends object>(data: T, columns: ColumnsType, omit?: React.Key[]) => {
    return columns
        .map((item, idx) => {
            if (omit && item.key && omit.includes(item.key)) return;
            const index = (item as ColumnType<any>).dataIndex;
            const value = getTableValue(data, index);

            return {
                label: item.title,
                value: item.render ? item.render(value, data, idx) : <div>{value}</div>,
            };
        })
        .filter((item) => typeof item != 'undefined');
};

const getMobilePagination = () => {
    return {
        showTotal: undefined,
        showLessItems: true,
        align: 'center'
    } as TablePaginationConfig;
};

export const AdaptiveTable = <T extends object>(props: AdaptiveTableProps<T>) => {
    const { isTabletOrMobile } = useAdaptiveContext();
    const { i18n } = useTranslation();
    const { t } = useTranslation('common');
    const { pagination, loading, dataSource, rowKey, collapseLabel, columns, omitColumns, onChange, locale } = props;

    const newPagination = {
        ...pagination,
        ...(isTabletOrMobile ? getMobilePagination() : {}),
    };

    const loadingProps = (typeof loading != 'undefined' && (typeof loading == 'object' ? loading : { spinning: loading })) || {};

    const items: CollapseProps['items'] = useMemo(
        () =>
            (dataSource &&
                dataSource.map((value) => {
                    const data = (columns && createList(value, columns, omitColumns)) || [];

                    return {
                        key: (rowKey && ((value[rowKey.toString() as keyof T] as React.Key) ?? undefined)) || undefined,
                        label: collapseLabel ? collapseLabel(value) : null,
                        children: (
                            <List
                                dataSource={data}
                                renderItem={(item) => (
                                    <List.Item>
                                        <Flex justify="space-between" align={'center'} className="w-full text-sm leading-4 text-right gap-x-2">
                                            <span className="flex-[1_0_min-content] text-left">{item.label?.toString()}</span>
                                            <div className='flex flex-[1_1_auto] justify-end'>
                                                {item?.value as React.ReactNode}
                                            </div>
                                        </Flex>
                                    </List.Item>
                                )}
                            />
                        ),
                    };
                })) ||
            [],
        [dataSource, i18n.language, columns]
    );

    return (
        <>
            {(isTabletOrMobile && (
                <Flex vertical align={'center'} justify={'center'} gap={16}>
                    <Spin wrapperClassName="w-full text-center" {...loadingProps}>
                        {(items.length > 0 && <Collapse className="w-full" items={items} accordion expandIconPosition="end" />) ||
                            ((locale?.emptyText as React.ReactNode) ?? <div className={'py-[74px]'}> {t('common:emptyText')}</div>)}
                    </Spin>
                    {items.length > 0 && (
                        <Pagination
                            {...newPagination}
                            className='w-full'
                            onChange={(page, perPage) =>
                                onChange &&
                                onChange(
                                    {
                                        current: page,
                                        pageSize: perPage,
                                    },
                                    {},
                                    [],
                                    {
                                        currentDataSource: [],
                                        action: 'paginate',
                                    }
                                )
                            }
                        />
                    )}
                </Flex>
            )) || <Table {...{ ...props, pagination: newPagination }}></Table>}
        </>
    );
};
