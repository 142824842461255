import React, { useEffect, useState } from 'react';
import { StatusBadgeStatus } from '@/types.ts';
import { Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import { StatusBadge } from '@/features/common/component/StatusBadge.tsx';
import alarm_badge_icon from '@/assets/alarm_badge_icon.svg';
import working_badge_icon from '@/assets/working_badge_icon.svg';

export interface ClientStatusBadge {
    state?: boolean;
    status?: StatusBadgeStatus | null;
}

export const ClientStatusBadge: React.FC<ClientStatusBadge> = ({ status, state }) => {
    const { t } = useTranslation('devices');
    const [iconSrc, setIconSrc] = useState(working_badge_icon);

    useEffect(() => {
        if ((status != null && status != 'working') || state != true) {
            setIconSrc(alarm_badge_icon);
        } else {
            setIconSrc(working_badge_icon);
        }
    }, [state, status]);

    return (
        <>
            <div className="flex bg-white rounded-[8px] justify-between items-center px-[14px] py-[16px]">
                <div className="flex gap-x-4 items-center">
                    {state ? <Badge status="success" text={t('online')} /> : <Badge status="error" text={t('offline')} />}
                    {status && <StatusBadge status={status} />}
                </div>
                <img src={iconSrc} className="w-[22px] h-[22px]" />
            </div>
        </>
    );
};
